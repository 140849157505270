import { IonContent, IonPage, IonFab, IonFabButton, IonButton, IonLabel, IonItem, IonIcon, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonModal, IonInfiniteScroll, IonInfiniteScrollContent, IonAlert, IonHeader, IonToolbar, IonButtons, IonTitle, IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, useIonAlert, useIonToast, IonLoading, IonSelect, IonSelectOption, IonCheckbox, IonItemDivider, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { add, call, documentLock, idCard, mail, people, remove, storefront, trash } from 'ionicons/icons';
import './Equipe.css';
import { useHistory } from 'react-router-dom';
import EquipeCad from '../EquipeCad/EquipeCad';
import { excluiUsuario, listaEquipe, listaOperacao, requestService, removerLoja, incluirLoja, patchUsuariosAcessosAgendamentos, gerarApikey } from '../../../../../Utils/Services';

const Equipe: React.FC = () => {

  const history = useHistory();
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [perfil, setPerfil] = useState(localStorage.getItem("perfil"));
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cpf, setCpf] = useState("");
  const [showResetSenha, setShowResetSenha] = useState(false);
  const [showExcluirUsuario, setShowExcluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showSucessoKey, setShowSucessoKey] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [lojaInserir, setLojaInserir] = useState("");
  const [usuario, setUsuario] = useState<any>();
  const [showGerarApikey, setShowGerarApikey] = useState(false);

  const [mostartAlerta] = useIonAlert();
  const [mostrarToast, fecharToast] = useIonToast();

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function funcRemoverLoja(usuario: string, loja: string) {

    console.log(usuario, loja);
    return fetch(removerLoja.url.replace("{cpf}", usuario.replace(/#/g, "%23")),
      {
        method: removerLoja.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include',
        body: JSON.stringify({ 'loja': loja })
      });
  }

  function funcIncluirLoja(usuario: string, loja: string) {

    console.log(usuario, loja);
    return fetch(incluirLoja.url.replace("{cpf}", usuario.replace(/#/g, "%23")),
      {
        method: incluirLoja.method,
        headers: [
          ["token", localStorage.getItem("token") || ""]
        ],
        credentials: 'include',
        body: JSON.stringify({ 'loja': loja })
      });
  }

  useIonViewWillEnter(() => {
    if (chamadaRealizada) {
      carregarUsuarios();
    }
  })

  const loadItens = () => {

    if (!chamadaRealizada) {
      setChamadaRealizada(true);
      carregarUsuarios();
    }

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader id="primeira" >
            <IonCardTitle>Aqui aparecem os usuários da equipe</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>

      <div style={{ width: '90%', margin: 'auto', marginTop: '10px', marginBottom: '10px', background: '#ffffff', borderRadius: '25px' }}>
        <IonGrid>
          <IonRow><IonCol><b>{value.nome}</b></IonCol></IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines='full'>
                <IonLabel>Dados</IonLabel>
              </IonItem>

              <IonItem lines='none'>
                <IonIcon icon={documentLock} slot='start'></IonIcon>
                <IonLabel className="equipeTexto">
                  <p style={{ fontSize: '10px' }}>Perfil</p>
                  {value.perfil == "Cliente" ? "Administrador" : "Usuário"}</IonLabel>
              </IonItem>
              <IonItem lines='none'>
                <IonIcon icon={idCard} slot='start'></IonIcon>
                <IonLabel className="equipeTexto">
                  <p style={{ fontSize: '10px' }}>Documento</p>
                  {cpf_mask(value.cpf)}</IonLabel>
              </IonItem>
              <IonItem lines='none'>
                <IonIcon icon={mail} slot='start'></IonIcon>
                <IonLabel className="equipeTexto">
                  <p style={{ fontSize: '10px' }}>E-mail</p>
                  {value.email}</IonLabel>
              </IonItem>
              <IonItem lines='none'>
                <IonIcon icon={call} slot='start'></IonIcon>
                <IonLabel className="equipeTexto">
                  <p style={{ fontSize: '10px' }}>Telefone</p>
                  {value.telefone}</IonLabel>
              </IonItem>

            </IonCol>
            <IonCol>
              <IonItem lines='full'>
                <IonLabel>Acessos</IonLabel>
              </IonItem>

              <IonItem lines='none'>
                <IonLabel>Agendamento</IonLabel>
                <IonSelect value={value?.acessos?.agendamento || 'sem acesso'} interface='alert' disabled={perfil != "Cliente" || value.cpf == localStorage.getItem("cpf")} onIonChange={(e) => { atualizaAcessoAgenda(value.cpf, e.detail.value) }}>
                  <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                  <IonSelectOption value="leitura">Leitura</IonSelectOption>
                  <IonSelectOption value="edicao">Edição</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem lines='none'>

                <IonLabel>Chave Integração</IonLabel>
                <IonButton size="small" fill='solid' shape="round" color="tertiary" expand='block' disabled={!(value.cpf == localStorage.getItem("cpf"))} onClick={() => { setCpf(value.cpf); setMensagem("Deseja gerar apikey? Caso já tenha outra apikey a mesma será desativa."); setShowGerarApikey(true) }}>Gerar Key</IonButton>
              </IonItem>
            </IonCol>
            <IonCol >
              <IonItem lines='full' hidden={!(value && value.lojas && value.lojas.length > 0)}>
                <IonLabel>Lojas</IonLabel>
                <IonSelect hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) && value.cpf != localStorage.getItem("cpf") ? false : true} name='Lojas' placeholder='Inserir' title='Lojas' multiple={false} cancelText="Cancel" okText="Ok" onIonChange={(e) => {
                  console.log(e);
                  mostartAlerta({
                    backdropDismiss: false,
                    header: 'Incluir Loja',
                    message: 'Realizar <strong>inclusão</strong> da loja?',
                    buttons: [
                      {
                        text: 'Cancelar',
                        role: 'cancel',
                      },
                      {
                        text: 'Confirmar',
                        cssClass: 'primary',
                        handler: () => {
                          setShowLoading(true);
                          let retorno = funcIncluirLoja(value.cpf, e.detail.value.clienteLojaId);
                          if (retorno) {
                            retorno?.then(async res => {
                              let body = await res.json();
                              if (res.status === 200) {
                                if (value.lojas) {
                                  value.lojas?.push({ clienteLojaId: e.detail.value.clienteLojaId, clienteLojaNome: e.detail.value.clienteLojaNome });
                                }
                                else {
                                  value.lojas = [{ clienteLojaId: e.detail.value.clienteLojaId, clienteLojaNome: e.detail.value.clienteLojaNome }];
                                }
                                //valorLoja.clienteLojaNome = "";
                                mostrarToast({
                                  duration: 2000,
                                  position: 'middle',
                                  buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                  message: 'Loja incluida com sucesso.'
                                });
                              }
                              else {
                                mostrarToast({
                                  duration: 2000,
                                  position: 'middle',
                                  buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                  message: body.message || 'Erro ao tentar incluir.'
                                });
                              }
                            }).catch(() => {
                              mostrarToast({
                                duration: 2000,
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: 'Erro ao tentar incluir.'
                              });
                            }).finally(() => {
                              setShowLoading(false);
                            });
                          }
                          else {
                            mostrarToast({
                              duration: 2000,
                              position: 'middle',
                              buttons: [{ text: 'ok', handler: () => fecharToast() }],
                              message: 'Erro ao tentar incluir.'
                            });
                            setShowLoading(false);
                          }
                          return true;
                        }
                      }
                    ],
                    onDidDismiss: (e) => console.log('did dismiss'),
                  })
                }} >
                  {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                    <IonSelectOption value={{ clienteLojaId: valor.clienteLojaId, clienteLojaNome: valor.clienteLojaNome }} onSelect={() => console.log("selecionado")}>{valor.clienteLojaNome}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              {value.lojas?.map((valorLoja: any, indiceLoja: any, arrLoja: any) => (
                <IonItem lines='none'>
                  <IonIcon icon={storefront} slot='start'></IonIcon>
                  <IonLabel>{valorLoja.clienteLojaNome}</IonLabel>
                  <IonIcon icon={trash} color="danger" onClick={() =>
                    mostartAlerta({
                      backdropDismiss: false,
                      header: 'Remover Loja',
                      message: 'Realizar <strong>remoção</strong>?',
                      buttons: [
                        {
                          text: 'Cancelar',
                          role: 'cancel',
                        },
                        {
                          text: 'Remover',
                          cssClass: 'primary',
                          handler: () => {
                            setShowLoading(true);
                            let retorno = funcRemoverLoja(value.cpf, valorLoja.clienteLojaId);
                            if (retorno) {
                              retorno?.then(res => {
                                if (res.status === 200) {
                                  valorLoja.clienteLojaNome = "";
                                  mostrarToast({
                                    duration: 2000,
                                    position: 'middle',
                                    buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                    message: 'Loja removida com sucesso.'
                                  });
                                }
                                else {
                                  mostrarToast({
                                    position: 'middle',
                                    buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                    message: 'Erro ao tentar remover.'
                                  });
                                }
                              }).catch(() => {
                                mostrarToast({
                                  position: 'middle',
                                  buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                  message: 'Erro ao tentar remover.'
                                });
                              }).finally(() => {
                                setShowLoading(false);
                              });
                            }
                            else {
                              mostrarToast({
                                position: 'middle',
                                buttons: [{ text: 'ok', handler: () => fecharToast() }],
                                message: 'Erro ao tentar remover.'
                              });
                              setShowLoading(false);
                            }
                            return true;
                          }
                        }
                      ],
                      onDidDismiss: (e) => console.log('did dismiss'),
                    })
                  }
                    hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) && value.cpf != localStorage.getItem("cpf") ? false : true}
                  ></IonIcon>
                </IonItem>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem lines="none" style={{ borderRadius: '25px' }} >
          <IonButton size="small" fill='outline' shape="round" color="tertiary" slot="start" disabled={perfil == "Usuário" || value.perfil == "Cliente"} hidden={true} onClick={() => { setCpf(value.cpf); setMensagem("Deseja realmente <strong>reiniciar</strong> a senha do " + value.nome + "?"); setShowResetSenha(true) }}>Alterar senha</IonButton>
          <IonButton size="small" fill='outline' shape="round" color="tertiary" slot="start" disabled={perfil == "Usuário" || value.cpf == localStorage.getItem("cpf")} onClick={() => { setUsuario(value); setShowDetalhes(true) }}>Alterar conta</IonButton>
          <IonButton size="small" fill='solid' shape="round" color="tertiary" slot="end" className="botaoExcluir" disabled={perfil == "Usuário" || value.cpf == localStorage.getItem("cpf")} onClick={() => { setCpf(value.cpf); setMensagem("Deseja realmente excluir a conta do " + value.nome + "?"); setShowExcluirUsuario(true) }}>Excluir conta</IonButton>
        </IonItem>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Registrando...'}

        />
      </div>
    );

  }

  function carregarUsuarios() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      const resp = await fetch(listaEquipe.url,
        {
          method: listaEquipe.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });
      let equipe = await resp.json();
      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        setArrayOperacoes(equipe.Items.sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1));
        //setArrayOperacoes(equipe.Items);
        if (equipe.LastEvaluatedKey) {
          setOperacaopk(equipe.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
        }
      }
    }
    consultar();
  }

  async function carregarMaisUsuarios($event: CustomEvent<void>) {

    await carregarMaisUsuariosChamada().then(() => {
      console.log("terminou resultados");//($event.target as HTMLIonInfiniteScrollElement).complete();
    });
    /*
        const consultar = async () =>{
          const resp = await fetch(listaOperacao.url,
            {
              method: listaOperacao.method,
              credentials: 'include'
            });
          const operacoes = await resp.json().then((operacoes) => {
            for (let i = 0; i < operacoes.Items.length; i++){
              arrayOperacoes.push(operacoes.Items[i]);
            }
          })
        }
        await consultar().then(() => {($event.target as HTMLIonInfiniteScrollElement).complete();});
    */
    ($event.target as HTMLIonInfiniteScrollElement).complete();


  }

  async function carregarMaisUsuariosChamada() {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    const res: Response = await fetch(listaOperacao.url + "?pk=" + operacaopk,
      {
        method: listaOperacao.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        console.log(arrayOperacoes.length);
        for (let i = 0; i < operacoes.Items.length; i++) {
          console.log("resultados");
          arrayOperacoes.push(operacoes.Items[i]);
        }
        console.log(arrayOperacoes.length);
        if (operacoes.LastEvaluatedKey) {
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
          console.log("finalizou");
        }
        //($event.target as HTMLIonInfiniteScrollElement).complete();
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");
  }

  async function closeModal() {
    setUsuario(null);
    setShowDetalhes(false);
    carregarUsuarios();
  }

  async function closeModalSimple() {
    setUsuario(null);
    setShowDetalhes(false);
  }

  function doRefresh(event: any) {
    // console.log('Begin async operation');
    //carregarOperacoes();
    // console.log("refresh" + statusOperacao);
    carregarUsuarios();
    setTimeout(() => {
      // console.log('Async operation has ended');
      event.detail.complete();
    }, 1200);
  }

  async function atualizaAcessoAgenda(usuario: any, perfil: any) {
    console.log(usuario, perfil);
    requestService(patchUsuariosAcessosAgendamentos.url.replace("{id}", usuario), {
      method: patchUsuariosAcessosAgendamentos.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      body: JSON.stringify({ "agendamento": perfil }),
      credentials: 'include'
    },
      null,
      (response: any) => {
        //carregarUsuarios();
      },
      (error: any) => {
        console.log(error);
      });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton>
              <IonIcon slot="icon-only" icon={people} />
            </IonButton>
          </IonButtons>
          <IonTitle>Equipe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="equipecontent" >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonModal isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); }}>
          <EquipeCad usuario={usuario} closeAction={closeModal} closeActionSimple={closeModalSimple}></EquipeCad>
        </IonModal>
        <IonButton hidden={true} onClick={() => { setUsuario(null); setShowDetalhes(true) }}>Novo</IonButton>
        <IonFab hidden={perfil == "Usuário"} vertical="top" horizontal="end" slot="fixed" edge>
          <IonFabButton color="tertiary" onClick={() => setShowDetalhes(true)} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        {loadItens()}
        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisUsuarios(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando mais usuários]..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonAlert
          isOpen={showResetSenha}
          onDidDismiss={() => setShowResetSenha(false)}
          header={'Reset de Senha'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                // requestService(resetSenhaUsuario.url.replace("{cpf}", cpf), {
                //   method: resetSenhaUsuario.method,
                //   headers: [["token", localStorage.getItem("token") || ""]],
                //   credentials: 'include'
                // },
                //   history,
                //   (response: any) => {
                //     setTextToast(response.message);
                //     setShowSucesso(true);
                //   },
                //   (error: any) => {
                //     setTextToast(error.message);
                //     setShowErro(true);
                //   });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showGerarApikey}
          onDidDismiss={() => setShowGerarApikey(false)}
          header={'Geração de APikey'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(gerarApikey.url.replace("{cpf}", cpf), {
                  method: gerarApikey.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    carregarUsuarios();
                    setTextToast(response.message);
                    setShowSucessoKey(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showExcluirUsuario}
          onDidDismiss={() => setShowExcluirUsuario(false)}
          header={'Exclusão de Usuário'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(excluiUsuario.url.replace("{cpf}", cpf), {
                  method: excluiUsuario.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    carregarUsuarios();
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showSucessoKey}
          onDidDismiss={() => setShowSucessoKey(false)}
          header={'CHAVE GERADA'}
          message={textToast}
          backdropDismiss={false}
          buttons={[{
            text: 'COPIAR CHAVE',
            cssClass: 'alert-button-confirm',
            handler: () => {
              navigator.clipboard.writeText(textToast);
            },
          }]}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
      </IonContent>
    </IonPage>
  );
};

export default Equipe;
