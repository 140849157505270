
const URL_BASE = "https://ce89z01jak.execute-api.sa-east-1.amazonaws.com/prd"; // Produção
//const URL_BASE = "https://e9gfr1dvgf.execute-api.sa-east-1.amazonaws.com/dev"; // Desenvolvimento
//const URL_BASE = "http://localhost:3000/dev";
//const URL_BASE = "http://localhost:3000/prd";

// variavel utilizada para indicar a pasta de gravação das imagens no s3
export const ambiente = "prd"
//export const ambiente = "dev"


//Sobre Usuario
export const logar = {url: URL_BASE+"/usuarios/{cpf}/autenticacao", method: "POST"};
export const confirmarLogin = {url: URL_BASE+"/usuarios/{cpf}/autenticacao/otp", method: "POST"};

export const enviarEmail = {url: URL_BASE+"/usuarios/{cpf}/autenticacao/email", method: "POST"};
export const autenticado = {url: URL_BASE+"/usuarios/autenticacao", method: "GET"};
export const listaEquipe = {url: URL_BASE+"/usuarios", method: "GET"};
export const cadastraEquipe = {url: URL_BASE+"/usuarios", method: "POST"};
export const alteraEquipe = {url: URL_BASE+"/usuarios/{cpf}", method: "PUT"};
export const removerLoja = {url: URL_BASE+"/usuarios/{cpf}/lojas", method: "DELETE"};
export const incluirLoja = {url: URL_BASE+"/usuarios/{cpf}/lojas", method: "PUT"};
export const excluiUsuario = {url: URL_BASE+"/usuarios/{cpf}", method: "DELETE"};
export const gerarApikey = {url: URL_BASE+"/usuarios/{cpf}/apikey", method: "PATCH"};
export const reportsBuscar = {url: URL_BASE+"/reports", method: "GET"};
export const operacaoChamarMotorista = {url: URL_BASE+"/operacoes/{pk}", method: "POST"};
export const operacaoLiberarOperacao = {url: URL_BASE+"/operacoes/{pk}", method: "PATCH"};
export const operacaoRegistrarAnotacao = {url: URL_BASE+"/operacoes/{pk}/anotacoes", method: "POST"};
export const operacaoAtualizarSemaforo = {url: URL_BASE+"/operacoes/{pk}/semaforo", method: "POST"};
export const getNota = {url: URL_BASE+"/notas/{id}", method: "GET"};

export const deslogar = {url: URL_BASE+"/usuarios/saida", method: "POST"};

export const onboarding_all = {url: URL_BASE+"/onBoarding/v1?onBoarding=1", method: "GET"};
export const usuarioCadastrar = {url: URL_BASE+"/usuario", method: "POST"};
export const confirmarCadastro = {url: URL_BASE+"/usuario/confirmar", method: "POST"};


export const verificaLogin = {url: URL_BASE+"/echoLogado", method: "GET"};

//Instrucoes
export const clienteCarregar = {url: URL_BASE+"/clientes/{id}", method: "GET"};
export const clienteAlterar = {url: URL_BASE+"/clientes/{id}", method: "PUT"};
export const instrucoesExcluir = {url: URL_BASE+"/clientes/{id}/instrucoes", method: "DELETE"};
export const instrucoesCadastrar = { url: URL_BASE + "/clientes/{id}/instrucoes", method: "POST" };

//Sobre Operacoes
export const incluirOperacao = {url: URL_BASE+"/operacoes", method: "POST"};
export const encerrarOperacao = {url: URL_BASE+"/operacoes/{id}", method: "PUT"};
export const listaOperacao = {url: URL_BASE+"/operacao", method: "GET"};
export const carregaOperacao = {url: URL_BASE+"/operacao/", method: "GET"};
export const incluirOcorrenciaOperacao = {url: URL_BASE+"/operacao", method: "PATCH"};
export const incluiNotaFiscal = { url: URL_BASE + "/operacoes/{id}/notas", method: "POST"};

//Parceiro
export const confirmaTokenMotorista = {url: URL_BASE+"/beneficios/{codigo}", method: "PUT"};
export const listaHistoricoBeneficios = {url: URL_BASE+"/beneficios", method: "GET"};

//Usuarios
export const patchUsuariosAcessosAgendamentos = { url: URL_BASE + "/usuarios/{id}/acessos/agendamento", method: "PATCH" };

//Agendamento
export const listaAgendamentos = {url: URL_BASE+"/agendamentos", method: "GET"};
export const cadastraAgendamento = {url: URL_BASE+"/agendamentos", method: "POST"};
export const excluiAgendamento = {url: URL_BASE+"/agendamentos/{pk}", method: "DELETE"};
export const finalizaAgendamento = {url: URL_BASE+"/agendamentos/{pk}", method: "PUT"};

export const urlBase = {url: URL_BASE, method: "GET"};
//export const listaOperacaoCompartilhada = {url: URL_BASE+"/operacao/compartilhada", method: "GET"};


export async function getJSONObject(url:string){
  const response = await fetch(url);
  const myJson = await response.json();
  return myJson;
}

export async function requestService(url: any, config: any, history:any, callbackSuccess: any, callbackError: any, redirectLogin?:boolean, getStatus?: boolean){
  //console.log("call api");
  //console.log(url);
  //console.log(config);
  return await fetch(url, config).then(res => {
    if(res.status === 401 && (redirectLogin || redirectLogin === undefined)){
      if(history) history.replace("/login");
      res.json().then(response => {
        response.status = res.status;
        callbackError(response);
      });
      }else if(res.status === 200 || res.status === 201){
      res.json().then(response => {
        if(getStatus)
          response.status = res.status
        callbackSuccess(response);
      });
    }else{
      res.json().then(response => {
        response.status = res.status;
        callbackError(response);
      });
    }
  });
}